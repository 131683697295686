<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-input :value="regionName" readonly placeholder="土地行政区划" style="width: 280px;">
                        <template #append>
                            <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                        </template>
                    </el-input>
                    <search-box v-model="key" class="ml-2" style="width: 280px;" @search="loadLands(true)" />
                </div>
                <div class="col-auto">
                    <span style="line-height: 40px;">
                        总面积：{{lands.tag}} 亩
                    </span>
                </div>
            </div>

            <el-table :data="lands.data" class="mt-2">
                <el-table-column label="名称/编号" sortable>
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" label="类型" sortable width="160" />
                <el-table-column prop="regionName" label="土地行政区划" sortable width="160" />
                <el-table-column prop="area" label="面积" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="作物" width="140">
                    <template #default="scope">
                        <el-button type="text" @click="showSetLandCropDialog(scope.row)">
                            <span v-if="scope.row.cropId">{{scope.row.cropName}}</span>
                            <span v-else class="text-info">无作物</span>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="lands.totalRecords"
                :current-page="landsPagination.currentPage" :page-size="landsPagination.pageSize" class="mt-3"
                @current-change="landsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="setLandCropDialogVisible" title="设置作物" :close-on-click-modal="false" width="400px">
            <el-form>
                <el-form-item label="作物" class="form-item-required">
                    <el-select v-model="land.cropId" placeholder="作物">
                        <el-option :value="null" label="无作物" />
                        <el-option v-for="crop in crops" :key="crop.id" :value="crop.id" :label="crop.name" />
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="setLandCrop">确定</el-button>
                <el-button @click="setLandCropDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="regionId=$event.id;regionName=$event.name;loadLands(true)" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                crops: [],
                regionId: null,
                regionName: null,
                key: null,
                lands: {},
                landsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                land: {},
                setLandCropDialogVisible: false,
                selectRegionDialogVisible: false,
            };
        },
        methods: {
            async loadCrops() {
                let response = await this.$axios.get('/api/Crop/GetCrops');
                this.crops = response.data;
            },
            async loadLands(resetPage = false) {
                if (resetPage) {
                    this.landsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Land/GetLandsByCompany', {
                    params: {
                        companyId: this.$store.state.companyId,
                        regionId: this.regionId,
                        key: this.key,
                        pageIndex: this.landsPagination.currentPage - 1,
                        pageSize: this.landsPagination.pageSize,
                    }
                });
                this.lands = response.data;
            },
            landsPaginationCurrentChange(page) {
                this.landsPagination.currentPage = page;
                this.loadLands();
            },
            showSetLandCropDialog(land) {
                this.land = JSON.parse(JSON.stringify(land));
                this.setLandCropDialogVisible = true;
            },
            async setLandCrop() {
                await this.$axios.post('/api/Land/SetLandCrop', {
                    id: this.land.id,
                    cropId: this.land.cropId,
                });
                this.setLandCropDialogVisible = false;
                this.loadLands();
            },
        },
        created() {
            this.loadCrops();
            this.loadLands();
        },
    };
</script>